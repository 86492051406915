<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('customer_sales_report.customer')}}</label>
                                <multiselect v-model="customer" :placeholder="$t('customer_sales_report.customer')" label="name"
                                             track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('customer_sales_report.from_date')}}</label>
                                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('customer_sales_report.to_date')}}</label>
                                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('group_by')}}</label>
                                <select name="" id="" v-model="filters.group_by" class="custom-select">
                                    <option value="customer">{{$t('customer_sales_report.customer')}}</option>
                                    <option value="invoice">{{$t('customer_sales_report.invoice')}}</option>
                                </select>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('order_by')}}</label>
                                <select name="" id="order_by" v-model="filters.order_by" class="custom-select">
                                    <option value="asc">{{$t('asc')}}</option>
                                    <option value="desc">{{$t('desc')}}</option>
                                </select>
                            </div>

                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!--begin::customer-->
        <div class="card card-custom mt-5">

            <div class="card-body">

                <div class="col-md-12 mb-5">
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10" :id="'summaryTableDetails'+filters.group_by+'Title'">
                                <!-- Clients Installments - Group By Client -->
                                <h4 class="text-center">{{ $t('customer_sales_report.customer_sales_report')}}</h4>
                                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} 
                                    {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span>
                                </h5>
                            </div>
                            <div class="col-12 mt-10 d-flex justify-content-end">
                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                    <button class="dropdown-item" @click="convertTableToExcelReport">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </button>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'print')">
                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                    </button>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by, 'pdf')">
                                        <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                    </button>
                                </b-dropdown>
                            </div>
                            <div class="col-12 mt-10">
                                <div class="table-responsive">
                                    <table v-if="filters.group_by == 'customer'" class="table table-bordered" :id="'summaryTableDetails'+filters.group_by">
                                        <thead>
                                        <tr id="myRow">
                                            <th>{{$t('customer_sales_report.date')}}</th>
                                            <th>{{$t('customer_sales_report.type')}}</th>
                                            <th>{{$t('customer_sales_report.name')}}</th>
                                            <th>{{$t('customer_sales_report.document_number')}}</th>
                                            <th>{{$t('customer_sales_report.shipping_fees')}}</th>
                                            <th>{{$t('customer_sales_report.value')}}</th>
                                            <th>{{$t('customer_sales_report.discounts')}}</th>
                                            <th>{{$t('customer_sales_report.taxes')}}</th>
                                            <th>{{$t('customer_sales_report.total')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(row, index) in data">
                                            <tr :key="'c'+index">
                                                <td colspan="9">{{row.label}}</td>
                                            </tr>
                                            <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                                                <td>{{row2.date}}</td>
                                                <td>{{row2.type}}</td>
                                                <td>{{row2.name}}</td>
                                                <td>{{row2.document_number}}</td>
                                                <td>{{row2.shipping_fees}}</td>
                                                <td>{{row2.value}}</td>
                                                <td>{{row2.discount}}</td>
                                                <td>{{row2.taxes}}</td>
                                                <td>{{row2.total}}</td>
                                            </tr>
                                            <tr :key="'d'+index">
                                                <td colspan="5">{{$t('total')}}</td>
                                                <td>{{row.total.total_value}}</td>
                                                <td>{{row.total.total_discounts}}</td>
                                                <td>{{row.total.total_taxes}}</td>
                                                <td>{{row.total.total_total}}</td>
                                            </tr>
                                        </template>

                                        </tbody>
                                        <tfoot>
                                        <tr v-if="dataTotal">
                                            <td colspan="5">{{$t('total')}}</td>
                                            <th>{{ dataTotal.sum_total_value}}</th>
                                            <th>{{ dataTotal.sum_total_discounts}}</th>
                                            <th>{{ dataTotal.sum_total_taxes}}</th>
                                            <th>{{ dataTotal.sum_total_total}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                    <table v-if="filters.group_by == 'invoice'" class="table table-bordered" :id="'summaryTableDetails'+filters.group_by">
                                        <thead>
                                        <tr id="myRow1">
                                            <th>{{$t('customer_sales_report.date')}}</th>
                                            <th>{{$t('customer_sales_report.type')}}</th>
                                            <th>{{$t('customer_sales_report.name')}}</th>
                                            <th>{{$t('customer_sales_report.document_number')}}</th>
                                            <th>{{$t('customer_sales_report.shipping_fees')}}</th>
                                            <th>{{$t('customer_sales_report.value')}}</th>
                                            <th>{{$t('customer_sales_report.discounts')}}</th>
                                            <th>{{$t('customer_sales_report.taxes')}}</th>
                                            <th>{{$t('customer_sales_report.total')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, index) in data" :key="'c'+getRandom()+index">
                                            <td>{{row.date}}</td>
                                            <td>{{row.type}}</td>
                                            <td>{{row.name}}</td>
                                            <td>{{row.document_number}}</td>
                                            <td>{{row.shipping_fees}}</td>
                                            <td>{{row.value}}</td>
                                            <td>{{row.discount}}</td>
                                            <td>{{row.taxes}}</td>
                                            <td>{{row.total}}</td>
                                        </tr>

                                        </tbody>
                                        <tfoot>
                                        <tr v-if="dataTotal">
                                            <td colspan="5">{{$t('total')}}</td>
                                            <th>{{ dataTotal.sum_total_value}}</th>
                                            <th>{{ dataTotal.sum_total_discounts}}</th>
                                            <th>{{ dataTotal.sum_total_taxes}}</th>
                                            <th>{{ dataTotal.sum_total_total}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>

                                </div>

                            </div>
                            <div class="col-12 text-center" v-if="page">
                                <button class="btn btn-warning" @click="loadMore">
                                    <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- End Filter -->

            </div>
        </div>
      <div class="selector-export" id="selectorExport"></div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

    export default {
        name: "index-customer-sales-report",

        data() {
            return {
                mainRoute: '/reports/customer-sales-report',
                mainRouteDependency: 'base/dependency',

                data: [],
                dataTotal: [],

                customers: [],


                data_more_details: [],
                details_total_sum: [],


                filters: {
                    customer_id: null,
                    group_by: 'customer',
                    from_date: null,
                    to_date: null,
                    order_by: 'desc',
                },
                currency_name: null,
                customer: null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page : 0,
            }
        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            'filters.group_by': function () {
                this.data = [];
                this.dataTotal = [];
            },

        },
        methods: {
            convertTableToExcelReport() {
                this.setReportLog('excel', 'customer sales report');
                TableToExcel.convert(document.getElementById('summaryTableDetails' + this.filters.group_by), {
                    name: this.$t('customer_sales_report.customer_sales_report') + '.xlsx',
                    sheet: {
                        name: 'Sheet 1'
                    }
                });
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },

            async getAuthUser() {
                await ApiService.get(`/get_auth`).then((response) => {
                    this.user = response.data.data;
                    this.currency_name = response.data.data.currency_name;
                    if (this.user) {
                        this.user_name = this.user.name;
                    }
                });
            },

            // selectFilter() {
            //     this.getData();
            // },

            doFilter() {
                this.page = 1;
                this.getData();
            },
            resetFilter() {
                this.customer = null;

                this.filters.customer_id = null;
                this.filters.group_by = 'customer';
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.order_by = 'desc';
                this.getData();

            },
            dataReport() {
                this.setReportLog('excel', 'customer sales report');
                return this.data;
            },
            loadMore() {
                this.page = this.page? (this.page + 1) : 1;
                this.getData();
            },
            getData() {
                ApiService.get(this.mainRoute, {params: {...this.filters, page:(this.page? this.page : 1)}}).then((response) => {
                    this.data = response.data.data.data;
                    this.dataTotal = response.data.data.total;
                });
            },

            getCustomers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/customers", {params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
            },
            printData(tableId, type) {
                this.setReportLog('pdf', 'customer sales report');

                // let divToPrint = document.getElementById(tableId);
                // let divTitleToPrint = document.getElementById(tableId + 'Title');
                // let newWin = window.open("");
                // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                // setTimeout(() => {
                //     newWin.print();
                //     newWin.close();
                // }, 100)
              exportPDFInnerAction(tableId , type, this.$t('customer_sales_report.customer_sales_report'), this.$t('customer_sales_report.customer_sales_report'));
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"),route:'/clients/reports'}, {title: this.$t("MENU.customer_sales_report")}]);

            this.getCustomers();
            this.getAuthUser();

        },
    };
</script>
<style>


.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>